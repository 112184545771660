exports.components = {
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cryo-offline-js": () => import("./../../../src/pages/cryo_offline.js" /* webpackChunkName: "component---src-pages-cryo-offline-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lab-27-js": () => import("./../../../src/pages/lab27.js" /* webpackChunkName: "component---src-pages-lab-27-js" */),
  "component---src-pages-printer-offline-js": () => import("./../../../src/pages/printer_offline.js" /* webpackChunkName: "component---src-pages-printer-offline-js" */),
  "component---src-pages-vacuum-offline-js": () => import("./../../../src/pages/vacuum_offline.js" /* webpackChunkName: "component---src-pages-vacuum-offline-js" */)
}

